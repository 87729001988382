import { ElMessage,ElLoading } from 'element-plus'

/**
 * 显示消息提示
 * @param {string} message - 消息内容
 * @param {string} type - 消息类型，可以是 'success', 'error', 'info', 'warning'
 * @param {number} duration - 消息持续时间，默认 3000 毫秒
 */
const showMessage = (message, type = 'warning', duration = 3000) => {
    ElMessage({
      message,
      type,
      duration,
    });
  };

 /**
 * 显示加载提示
 * @returns {Function} 关闭加载的方法
 */
const showLoading = () => {
  const loadingInstance = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });

  // 返回一个关闭加载的方法
  return () => loadingInstance.close();
};

const myFunc = {
    showMessage,
    showLoading

}


  export default myFunc;