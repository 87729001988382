// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import {useCounterStore} from '@/stores/counter'
import myFunc from '@/utils/tools';






const routes = [
  {
    path: '/',
    redirect: '/login/page',

    // 根据用户是否登录
    // const isLoggedIn = useCounterStore().token;
    // return isLoggedIn ? '/main/page' : '/login/page';

  },
 
  {
    path: '/login/page',
    name: 'loginPage',
    component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/login/LoginPage.vue'),

    
  },

  {
    path: '/main/page',
    name: 'mainPage',
    component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/MainPage.vue'),
    

    children: [

      {
        path: '/home/page',
        name: 'homePage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/home/HomePage.vue'),
        meta: { requiresAuth: true }

      },
      {
        path: '/goods/page',
        name: 'goodsPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/goods/GoodsPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/add/goods',
        name: 'addGoods',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/goods/AddGoods.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/edit/goods',
        name: 'editGoods',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/goods/EditGoods.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: '/order/page',
        name: 'orderPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/order/OrderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/page',
        name: 'userPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/user/UserPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/money/page',
        name: 'moneyPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/money/MoneyPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/money/list',
        name: 'moneyList',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/money/MoneyList.vue'),
        meta: { requiresAuth: true }
      },

      
      {
        path: '/android/version',
        name: 'androidVersion',

        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/version/AndroidVersion.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/add/android',
        name: 'addAndroid',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/version/AddAndroid.vue'),
        meta: { requiresAuth: true }
      },
        
      {
        path: '/ios/version',
        name: 'iosVersion',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/version/IosVersion.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/add/ios',
        name: 'addIos',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/version/AddIos.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/chat/page',
        name: 'chatPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/chat/ChatPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/page',
        name: 'staffPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/staff/StaffPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/notice/page',
        name: 'noticePage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/notice/NoticePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/message/page',
        name: 'messagePage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/notice/MessagePage.vue'),
        meta: { requiresAuth: true }
      },
      
      {
        path: '/send/notice',
        name: 'sendNotice',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/notice/SendNotice.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/send/message',
        name: 'sendMessage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/notice/SendMessage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/data',
        name: 'UserData',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/statistic/UserData.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/log/page',
        name: 'logPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/system/LogPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/text/page',
        name: 'textPage',
        component: () => import(/* webpackChunkName: "chunk-name" */  '@/views/TextPage.vue'),
      },

      

      


    ]
  },




];



const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 模式的路由
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useCounterStore();

  if (to.matched.some(record => record.meta.requiresAuth) && !authStore.token) {
    // console.log("用户未登录，重定向到登录页面");
    next('/login/page'); 
                    myFunc.showMessage('请登录~',)
  } else {
    // console.log("用户已登录，继续导航");
    next(); // 继续导航
  }
});

export default router;