import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import IndexPage from "./views/IndexPage.vue";
import { useCounterStore } from "@/stores/counter";
export default {
  __name: 'App',
  setup(__props) {
    const counterStore = useCounterStore();

    // 加载用户信息
    counterStore.loadUser();
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(IndexPage);
    };
  }
};