// src/stores/counter.js
import router from '@/router';
import myFunc from '@/utils/tools';
import { defineStore } from 'pinia';

// 定义一个名为 'counter' 的 store
export const useCounterStore = defineStore('counter', {

    // 初始化 值
    state: () => ({
        token: localStorage.getItem('token') || null, // 初始化时从 localStorage 读取 token
        user: null, // 存储用户信息
            return: {
    
    
                // 是否显示导航
                flagMenu: false,
                // 左侧导航宽度
                leftWidth: 260,
    
                count: 0,
        },
    }),


    getters: {

        // 管理左侧导航状态
        setFlagMenuState() {
            this.flagMenu = !this.flagMenu
            this.setLeftWidth(this.flagMenu)
        },



    },

    // 异步操作，会修改state状态 里面的值
    actions: {

        // 设置用户 token
        login(token, user) {
            this.token = token; // 设置 token
            this.user = user; // 设置用户信息
         
            localStorage.setItem('token', token); // 登录后存储 token
            localStorage.setItem('user', JSON.stringify(user)); // 存储用户信息
          },

          // 清楚 token
          loginOut() {
            router.push('/login/page')
            myFunc.showMessage('请登录~',)
            
          
          
            localStorage.removeItem('token'); // 注销后移除 token
            localStorage.removeItem('user'); // 移除用户信息
     
            this.token = null; // 清除 token
            this.user = null; // 清除用户信息
         
          },
          // 初始化 用户信息
          loadUser() {
            const storedUser = localStorage.getItem('user');
            if(storedUser){
                
                this.user = JSON.parse(storedUser)
            }else{
                this.user = null;
                router.push('/login/page')
                // myFunc.showMessage('请登录~',)
            }
    

          },
          
        increment() {
            this.count++;
        },

        // 设置 导航状态
        setIsShowMenuFlag(flag) {
            this.flagMenu = flag
            this.setLeftWidth(this.flagMenu)

        },

        // 管理左侧导航 ui宽度
        setLeftWidth(flag) {
            if (flag == false) {
                this.leftWidth = 260
            } else {
                this.leftWidth = 65
            }

        }
    },
});